import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import inspectionReportService from "../../../services/inspectionReportService";
import { formatDate } from "../../helpers/formatDateHelper";

const InspectionReportsTableItem = ({ report }) => {
  const handleOpenReport = (id) => {
    inspectionReportService.getInspectionReportById(id)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch PDF');
      }
      return response.blob();
    })
    .then((blob) => {
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);   
    })
  } 

    return (
      <TableRow onClick={() => handleOpenReport(report.inspectionId)}>
        <TableCell>{report.establishmentName}</TableCell>
        <TableCell>{report.identity}</TableCell>
        <TableCell>{report.districtShortName}</TableCell>
        <TableCell>{formatDate(report.inspectionPerformed)}</TableCell>
      </TableRow>
    );
};

export default InspectionReportsTableItem;