/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/auth`;

const getCodeChallenge = () => {
  return fetch(apiEndpoint , {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};

const getToken = async (code) => {
  return fetch(apiEndpoint + "/token" , {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(code),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};

const refreshToken = async (user) => {
  return fetch(apiEndpoint + `/token/${user.ssn}/refresh` , {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(user.accessToken.rawData),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};
const logOut = async (userSSN) => {
  return fetch(apiEndpoint + `/${userSSN}/logout` , {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
}
const fakeUser = () => {
  return fetch(apiEndpoint + `/fakeuser` , {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
}

export default { getCodeChallenge, getToken, fakeUser, refreshToken, logOut };