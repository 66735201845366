import { Modal, Paper, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import "./NewInspectionAddPlaceModal.css";
import CancelButton from '../CancelButton/CancelButton';
import ConfirmationButton from '../ConfirmationButton/ConfirmationButton';
const NewInspectionAddPlaceModal = ({ open, handleConfirm, handleCancel, individual}) => {
  const [addedPlace, setAddedPlace] = useState("");
  const handleInput = e => {
    setAddedPlace(e.target.value)
  }
  return (
    <>
      <Modal
        className="add-place-modal"
        open={open}
        onClose={handleCancel}
      >
        <Paper className="add-place-modal-paper">
          <div className='add-place-modal-paper-title'>
            <b>{individual.nafn}</b>
            <b>{individual.kennitala}</b>
          </div>
          <div className='add-place-modal-paper-textfield'>
            <TextField 
              label="Staðsetning eftirlits" 
              variant="standard"
              size='medium'
              multiline 
              onChange={handleInput}
              value={addedPlace}
              name="addedPlace"
              inputProps={{ maxLength: 50 }}
              helperText={`${addedPlace.length}/50 stafir`}
              />
          </div>
          <div className='add-place-modal-buttons'>
            <CancelButton handleCancel={handleCancel} title="Hætta við" />
            <ConfirmationButton handleSubmit={() => handleConfirm(addedPlace)} title="Staðfesta" />
          </div>
        </Paper>
      </Modal>
    </>
  );
}
export default NewInspectionAddPlaceModal;