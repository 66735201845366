import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import NewInspectionFindIndividualTableItem from "../NewInspectionFindIndividualTableItem/NewInspectionFindIndividualTableItem";

const NewInspectionFindIndividualTable = ({ individuals, handleSelectIndividual }) => {
  return (
      <Table className="company-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell><b>Nafn</b></TableCell>
            <TableCell><b>Kennitala</b></TableCell>
            <TableCell><b>Heimili</b></TableCell>
            <TableCell><b>Staður</b></TableCell>
            <TableCell><b>Póstnúmer</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {individuals.length > 0 ? (
            individuals.map((individual, i) => (
              <NewInspectionFindIndividualTableItem
                individual={individual}
                key={i}
                handleSelectIndividual={handleSelectIndividual}
              />
            ))
          ) : (
            <TableRow>
              <TableCell>
                Ekkert fannst
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
  );
};

export default NewInspectionFindIndividualTable;