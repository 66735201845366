import { useState, useEffect } from "react";
import judgementService from "../services/judgementService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";

const useGetAllJudgements = () => {
  const [judgements, setJudgements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
  const { refreshToken } = useRefreshToken();
  if(isTokenExpired) {
    refreshToken(user);
    setIsTokenExpired(user.tokenValidTo < new Date())
  }

  useEffect(() => {
    !isTokenExpired &&
    judgementService
      .getAllJudgements(user.accessToken.rawData)
      .then((judgements) => {
        setJudgements(judgements);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [user.accessToken.rawData, isTokenExpired]);
  return { judgements, isLoading, error };
};
export default useGetAllJudgements;