import { useState } from 'react';
import loginService from '../services/loginService';
import { useDispatch, useSelector } from 'react-redux';
import { setLogoutUser } from "../redux/actions/userActions";

const useLogOut = () => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const redirect = () => {        
        window.location.href = `https://identity-server.staging01.devland.is/connect/endsession?id_token_hint=${user.accessToken.rawData}&post_logout_redirect_uri=https%3A%2F%2Feftirlit-dev.mast.is/`;
    }

    const logOut = async (userSSN) => {
        return await loginService.logOut(userSSN)
            .then(() => { 
                setError(null); 
                dispatch(setLogoutUser());
            })
            .catch(error => setError(error))
            .finally(() => {redirect();});
    }
    return { error, logOut };
}
export default useLogOut;