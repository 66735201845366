import { useState } from 'react';
import inspectionService from '../services/inspectionService';
import useRefreshToken from './useRefreshToken';
import { useSelector } from 'react-redux';

const useCloseInspection = () => {
    const user = useSelector((state) => state.user);
    const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
    const { refreshToken } = useRefreshToken();
    if(isTokenExpired) {
      refreshToken(user);
      setIsTokenExpired(user.tokenValidTo < new Date())
    }
    const [error, setError] = useState(null);
    const closeInspection = async (id) => {
        return await inspectionService.closeInspection(id, user.accessToken.rawData)
            .then(() => { setError(null);})
            .catch(error => setError(error))
            .finally(() => {});
    }
    return { error, closeInspection };
}
export default useCloseInspection;