import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import "./InspectionItemsListItemEdit.css";
import CancelButton from "../CancelButton/CancelButton";

const InspectionItemsListItemEdit = ({ item, secondaryValue, handleRemoveItem }) => {
  console.log(item)
  return (
    <TableRow className="inspection-items-list-item-edit">
        <TableCell>{item.isName || item.itemIsName}</TableCell>
        <TableCell align="right">{item[secondaryValue]}</TableCell>
        <TableCell align="right" onClick={() => handleRemoveItem(item)}><CancelButton title="Eyða"/></TableCell>
    </TableRow>
  );
};

export default InspectionItemsListItemEdit;