import React, { useState } from "react";
import "./CalendarSelection.css";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CalendarSelection = ({
    values,
    label,
    valueName,
    helperText,
    errors
}) => {
    const split = values[valueName] ? values[valueName].split("-") : "";
    const selectedDate = split[1] + "-" + split[0] + "-" + split[2];
    const [refresh, setRefresh] = useState(false);
    const handleChangeDate = (newDate) => {
        values[valueName] = newDate.format('DD-MM-YYYY');
        setRefresh(!refresh);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            closeOnSelect={true}
            defaultValue={Date.Now}
            value={dayjs(selectedDate)}
            onChange={(newDate) => handleChangeDate(newDate)}
            variant="inline"
            format="DD/MM/YYYY"
            slotProps={{
                textField: {
                  helperText: errors[valueName] ? <div style={{color : "red"}}>{errors[valueName]}</div> : helperText,
                },
            }}
            PopoverProps={{
                anchorOrigin: { horizontal: "center", vertical: "bottom" },
                transformOrigin: { horizontal: "center", vertical: "bottom" }
              }}
          />
       </LocalizationProvider>
    );
};

export default CalendarSelection;
