import { useState, useEffect } from "react";

const useForm = (initState, validate, submitHandler, onError) => {
    const [values, setValues] = useState(initState);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (isSubmitting) {
            const err = Object.keys(errors).length > 0;
            if (err) {
                setSubmitting(false);
                if (onError) {
                    onError();
                }
            } else {
                submitHandler(values);
                setSubmitting(false);
            }
        }
    }, [errors, values, submitHandler, isSubmitting, onError]);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate(values);
        setErrors(validationErrors);
        setSubmitting(true);
    };

    const resetFields = () => {
        setValues(initState);
    };

    return {
        handleSubmit,
        handleChange,
        resetFields,
        values,
        errors, 
        setValues
    };
};

export default useForm;