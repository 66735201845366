import { handleErrors, handleData } from './serviceHandlers';
import endpoint from './endpoint';

const apiEndpoint = `${endpoint}/api/inspectionreports/`;
// https works but not http

const getPreviewPdfByInspectionId = (id, accessToken) => {
    return fetch(apiEndpoint + `inspectionpreviewpdf/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/pdf', // Set the appropriate Accept header
        "Authorization": "Bearer " + accessToken
      },
    }).catch((error) => Promise.reject(error));
  };

const getPdfByInspectionId = (id, accessToken) => {
  return fetch(test + `/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/pdf', // Set the appropriate Accept header
      "Authorization": "Bearer " + accessToken
    },
  }).catch((error) => Promise.reject(error));
};

const createPdf = async (id, accessToken) => {
  try {
    const response = await fetch(test + `/createinspectionreport`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(id), // Send the id as an object with a key named 'id'
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + accessToken
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    // Handle success
    return response.json();
  } catch (error) {
    throw new Error('Failed to create PDF');
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPreviewPdfByInspectionId,
  getPdfByInspectionId,
  createPdf
};

