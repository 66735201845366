import React, { useState } from 'react';
import NewInspectionFindIndividualContainer from '../NewInspectionFindIndividualContainer/NewInspectionFindIndividualContainer';
import { TextField } from '@material-ui/core';
import NewInspectionAddPlaceModal from '../NewInspectionAddPlaceModal/NewInspectionAddPlaceModal';
import { useNavigate } from 'react-router';

const NewInspectionContainer = () => {
  const [kennitala, setKennitala] = useState("");
  const [individual, setIndividual] = useState(null);
  const [isAddPlaceModalOpen, setAddPlaceModalOpen] = useState(false);
  let navigate = useNavigate(); 

  const handleInput = e => {
    setKennitala(e.target.value)
  }
  const errors = {
    kennitala: ""
  }
  if(kennitala.length < 6) {
    errors.kennitala = "Slá þarf inn 6 stafi"
  }
  const handleSelectIndividual = (individual) => {
    setIndividual(individual)
    setAddPlaceModalOpen(true);
  }
  const handleConfirmIndividual = (addedPlace) => {
    let path = `/preparation`; 
    const newIndividual = {
      identity: individual.kennitala,
      establishmentName: addedPlace,
      billingSentToSSN: individual.kennitala,
      presentName: individual.nafn
    }
    navigate(path, {state:{farm: newIndividual}});  
  };
  const handleCloseAddedPlaceModal = () => {
    setAddPlaceModalOpen(false);
  }
  return (
    <div>
      <TextField 
        label="Kennitala eftirlitsþega" 
        variant="standard" 
        onChange={handleInput}
        value={kennitala}
        error={errors.kennitala}
        name="kennitala"
        helperText={errors.kennitala}
      />
      {
        kennitala.length > 5 &&
        <NewInspectionFindIndividualContainer kennitala={kennitala} handleSelectIndividual={handleSelectIndividual}/>
      }
      { individual && <NewInspectionAddPlaceModal open={isAddPlaceModalOpen} individual={individual} handleCancel={handleCloseAddedPlaceModal} handleConfirm={handleConfirmIndividual}/> }
    </div>
  );
}

export default NewInspectionContainer;