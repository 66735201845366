import { useState } from 'react';
import loginService from '../services/loginService';
import { useDispatch } from 'react-redux';
import { setRefreshToken } from "../redux/actions/userActions";

const useRefreshToken = () => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const refreshToken = async (user) => {
        return await loginService.refreshToken(user)
            .then((token) => { setError(null); dispatch(setRefreshToken(token));
            })
            .catch(error => setError(error))
            .finally(() => {});
    }
    return { error, refreshToken };
}
export default useRefreshToken;