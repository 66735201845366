import React from 'react';
import useGetIndividualBySSN from '../../../hooks/useGetIndividualBySSN';
import NewInspectionFindIndividualTable from '../NewInspectionFindIndividualTable/NewInspectionFindIndividualTable';

const NewInspectionFindIndividualContainer = ({kennitala, handleSelectIndividual}) => {
  const { individuals } = useGetIndividualBySSN(kennitala);
  return (
    <div>
      <NewInspectionFindIndividualTable individuals={individuals} handleSelectIndividual={handleSelectIndividual}/>
    </div>
  );
}

export default NewInspectionFindIndividualContainer;