import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Modal, Paper, Select, TextareaAutosize } from "@material-ui/core";
import CalendarSelection from "../CalendarSelection/CalendarSelection";
import useGetAllJudgements from "../../../hooks/getAllJudgements";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import CancelButton from "../CancelButton/CancelButton";
import InspectionDeviationImage from "../InspectionDeviationImage/InspectionDeviationImage";


const InspectionFollowUpDeviationModal = ({ open, handleClose, item, followUpChecksToShow, setItemSelected}) => {
  const { judgements } = useGetAllJudgements();
  const [webcamIsOpen, setWebcamIsOpen] = useState(false);
  const [changeValue, setChangeValue] = useState(false);
  const inspectionItemSubmitHandler = ( ) => {
    if(item.judgementId) {
      setItemSelected(null);
      handleClose();
    }
  }
  const errors = {
    deadlineToResolve: "",
  }
  const handleChangeJudgementId = (e) => {
    let judgement = judgements.find(j => j.id.toString() === e.target.value.toString());
    item.judgementId = e.target.value;
    item.judgementIsName = judgement.isName;
    setChangeValue(!changeValue);
  }
  const handleChangeCalendarSelection = (e) => {
    item.deadlineToResolve = e.target.value;
    setChangeValue(!changeValue)
  }
  const handleChangeComment = (e) => {
    item.comment = e.target.value;
    setChangeValue(!changeValue)
  }
  const helperTextColor = item.comment.length < 300 ? "green" : "red";
  return (
    <Modal
      className="inspection-item-modal"
      open={open}
      onClose={handleClose}
    >
      <Paper className="inspection-item-modal-paper">
        <div className="inspection-item-modal-paper-title">
          {item.itemName}
        </div>
        <div className="inspection-item-modal-radio-buttons">
        {item.judgementId === "" && (<div style={{color: 'red'}}>{"Velja þarf aðgerð"}</div>)}
        <FormControl variant="filled" fullWidth> 
          <InputLabel>Veldu aðgerð</InputLabel>
            <Select
              label="Veldu aðgerð"
              name="judgementId"
              value={item.judgementId.toString()}
              onChange={handleChangeJudgementId}
            >          
            {
              judgements.map((judgement) => (
                <MenuItem 
                  key={judgement.id} 
                  value={judgement.id.toString()}
                >
                  {judgement.isName}
                </MenuItem>
              ))
            } 
          </Select>
        </FormControl>
        </div>
        <div>
          <CalendarSelection 
            values={item} 
            valueName="deadlineToResolve" 
            handleChange={handleChangeCalendarSelection} 
            label="Frestur til" 
            disabled={false}
            errors={errors}
          />
        </div>
        <div className="inspection-item-modal-paper-text">
          <div>Skýring</div>
          <TextareaAutosize
            aria-label="empty textarea"
            value={item.comment}
            onChange={handleChangeComment}
            name="comment"
            placeholder="Skrá skýringu hér ..."
            maxLength={350}
            style={{
              minWidth: 350,
              minHeight: 100,
              maxWidth: 350,
              maxHeight: 300,
            }}
          />
          <div style={{color: helperTextColor}}>{item.comment.length} / 350</div>
        </div>
        <div className="inspection-item-detail-image">
          <InspectionDeviationImage 
            inspectionDetailValues={item} 
            webcamIsOpen={webcamIsOpen} 
            setWebcamIsOpen={setWebcamIsOpen} 
            incomingInspection={true}
          />
        </div>
        <div className="inspection-item-button-area">
          <CancelButton 
              handleCancel={handleClose} 
              title="Hætta við frávik" />
          <ConfirmationButton 
              handleSubmit={ inspectionItemSubmitHandler } 
              title="Staðfesta frávik" />
        </div>
      </Paper>
    </Modal>
  );
};
export default InspectionFollowUpDeviationModal;