import React from "react";
import { TableRow, TableCell } from "@material-ui/core";

const NewInspectionFindIndividualTableItem = ({ individual, handleSelectIndividual }) => {
  return (
    <TableRow className="company-item" onClick={() => handleSelectIndividual(individual)}>
      <TableCell>{individual.nafn}</TableCell>
      <TableCell>{individual.kennitala}</TableCell>
      <TableCell>{individual.heimili}</TableCell>
      <TableCell>{individual.postfang}</TableCell>
      <TableCell>{individual.postnumer}</TableCell>
    </TableRow>
  );
};

export default NewInspectionFindIndividualTableItem;