/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/inspectionreports`;

const getInspectionReportPreviewById = (id, accessToken) => {
  return fetch(apiEndpoint + `/inspectionpreviewpdf/${id}`, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/pdf",
      "Authorization": "Bearer " + accessToken
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};

const getInspectionReportById = async (id, accessToken) => {
  return fetch(apiEndpoint + `/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/pdf', // Set the appropriate Accept header
      "Authorization": "Bearer " + accessToken
    },
  }).catch((error) => Promise.reject(error));
};

const getInspectionReportsByFarmId = (id, accessToken) => {
  return fetch(apiEndpoint + `/${id}/reports`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken
    },
  })
  .then(handleErrors)
  .then(handleData)
  .catch((error) => Promise.reject(error));
}

export default { getInspectionReportById, getInspectionReportPreviewById, getInspectionReportsByFarmId };