import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useGetAllOpenInspectionsByUser from '../../../hooks/useGetAllOpenInspectionsByUser';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { formatDate } from '../../helpers/formatDateHelper';
import { useNavigate } from 'react-router';
import useGetAllInspectionTypes from '../../../hooks/useGetAllInspectionTypes';
import "./OpenInspectionByUserView.css";
import CancelButton from '../CancelButton/CancelButton';
import useDeleteOpenInspection from '../../../hooks/useDeleteOpenInpsection';
import { ToastContainer, toast } from "react-toastify";
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import inspectionService from '../../../services/inspectionService';

const OpenInspectionByUserView = () => { 
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { inspectionTypes } = useGetAllInspectionTypes();
  let navigate = useNavigate(); 
  const user = useSelector((state) => state.user);
  const { inspections, isLoading } = useGetAllOpenInspectionsByUser(user.ssn)
  const { handleDelete } = useDeleteOpenInspection();
  const handleConfirmDeletion = async () => {
    const index = inspections.findIndex(obj => obj.id === itemToDelete);
    if (index > -1) {
      inspections.splice(index, 1);
    }
    await handleDelete(itemToDelete);
    toast.success("Færslu hefur verið eytt!");
    setConfirmModalIsOpen(false);
    setRefresh(!refresh);
  }
  const handleCancelDeletion = () => {
    setItemToDelete(null);
    setConfirmModalIsOpen(false);
  }
  const handleDelteOpenInspection = (id) => {
    setConfirmModalIsOpen(true);
    setItemToDelete(id)
  }
  const handleOpenOpenInspection = async (inspection) => {
    console.log(inspection.id);
    let inspectionToGet;
    await inspectionService.getOpenInspectionById(inspection.id).then((insp) => {inspectionToGet = insp})
    .catch((error) => console.log(error))
    .finally(() => {
    });
    console.log(inspectionToGet);
    let path = `/inspection`; 
    navigate(path, {state:{inspectionTypes: inspectionTypes, inspectionTypeInInspection: inspectionToGet.inspectionTypeInInspection, inspection: inspectionToGet}}); 
  }
  const handleBack = () => {
    let path = `/company`;
    navigate(path);
  }
    return (
      <>
        {isLoading ? <LoadingScreen /> : (
        <>
          <div className='open-inspection-header'>Opin eftirlit</div>
          <Table className="open-inspection-item-table-container" size="small">
            <TableHead className='open-inspection-item-table-header'>
              <TableRow>
                <TableCell>Staður</TableCell>
                <TableCell align="right">Stund</TableCell>
                <TableCell align="right">Ástæða</TableCell>
                <TableCell align="right">Aðgerð</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inspections.length > 0 ? (
                inspections.map((inspection, i) => (
                  <TableRow className="open-inspection-items-list-item" key={i}>
                    <TableCell onClick={() => handleOpenOpenInspection(inspection)}>{inspection.establishmentName}</TableCell>
                    <TableCell onClick={() => handleOpenOpenInspection(inspection)} align="right">{formatDate(inspection.inspectionPerformed)}</TableCell>
                    <TableCell onClick={() => handleOpenOpenInspection(inspection)} align="right">{inspection.reasonName}</TableCell>
                    <TableCell className="open-inspection-delete-button" onClick={() => handleDelteOpenInspection(inspection.id)} align="right">Eyða</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="open-inspection-item-table-no-items">
                    Ekki tókst að sækja opin eftirlit ...
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <CancelButton handleCancel={handleBack} title="Til baka"/>
          <ToastContainer autoClose={2000} />
          <ConfirmModal open={confirmModalIsOpen} handleConfirm={handleConfirmDeletion} handleCancel={handleCancelDeletion} title="Ertu viss um að þú viljir eyða skráningu?"/>
        </>
        )}
      </>
    );
}
export default OpenInspectionByUserView;